var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modals.edition)?_c('Modal',{staticClass:"editions",on:{"close":function($event){return _vm.toggleModal('edition')}}},[(!_vm.isCustom)?_c('div',[_c('h3',[_vm._v("Select an edition:")]),_c('ul',{staticClass:"editions"},[_vm._l((_vm.editions),function(edition){return _c('li',{key:edition.id,staticClass:"edition",class:['edition-' + edition.id],style:({
          backgroundImage: `url(${require('../../assets/editions/' +
            edition.id +
            '.png')})`
        }),on:{"click":function($event){return _vm.setEdition(edition)}}},[_vm._v(" "+_vm._s(edition.name)+" ")])}),_c('li',{staticClass:"edition edition-custom",style:({
          backgroundImage: `url(${require('../../assets/editions/custom.png')})`
        }),on:{"click":function($event){_vm.isCustom = true}}},[_vm._v(" Custom Script / Characters ")])],2)]):_c('div',{staticClass:"custom"},[_c('h3',[_vm._v("Load custom script / characters")]),_vm._v(" To play with a custom script, you need to select the characters you want to play with in the official "),_c('a',{attrs:{"href":"https://script.bloodontheclocktower.com/","target":"_blank"}},[_vm._v("Script Tool")]),_vm._v(" and then upload the generated \"custom-list.json\" either directly here or provide a URL to such a hosted JSON file."),_c('br'),_c('br'),_vm._v(" To play with custom characters, please read "),_c('a',{attrs:{"href":"https://github.com/TLSM/townsquare#custom-characters","target":"_blank"}},[_vm._v("the documentation")]),_vm._v(" on how to write a custom character definition file. "),_c('b',[_vm._v("Only load custom JSON files from sources that you trust!")]),_c('h3',[_vm._v("Some popular custom scripts:")]),_c('ul',{staticClass:"scripts"},_vm._l((_vm.scripts),function(script,index){return _c('li',{key:index,on:{"click":function($event){return _vm.handleURL(script[1])}}},[_vm._v(" "+_vm._s(script[0])+" ")])}),0),_c('input',{ref:"upload",attrs:{"type":"file","accept":"application/json"},on:{"change":_vm.handleUpload}}),_c('div',{staticClass:"button-group"},[_c('div',{staticClass:"button",on:{"click":_vm.openUpload}},[_c('font-awesome-icon',{attrs:{"icon":"file-upload"}}),_vm._v(" Upload JSON ")],1),_c('div',{staticClass:"button",on:{"click":_vm.promptURL}},[_c('font-awesome-icon',{attrs:{"icon":"link"}}),_vm._v(" Enter URL ")],1),_c('div',{staticClass:"button",on:{"click":_vm.readFromClipboard}},[_c('font-awesome-icon',{attrs:{"icon":"clipboard"}}),_vm._v(" Use JSON from Clipboard ")],1),_c('div',{staticClass:"button",on:{"click":function($event){_vm.isCustom = false}}},[_c('font-awesome-icon',{attrs:{"icon":"undo"}}),_vm._v(" Back ")],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }